* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button {
  cursor: pointer;
  /* border: rgb(204, 204, 204); */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.socialIcons:hover {
  animation: pulse 2s infinite;
}

/* Tailwind Animation Starts */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes ping {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  75% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.underline-animation::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.5s;
}

/* Apply the animation on hover */
.underline-animation:hover::after {
  width: 100%;
}
@keyframes underlineAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Tailwind CSS Ends */
